import React from 'react'
import Cta from './style'
import { Col, Container, Row } from 'react-bootstrap'
import promoBg from "~image/home-agency/promo-l5-img.png"
import Data from '~data-yaml/home.yml'
import { Link } from "~components"
import Content from "../ContentOne/style"

export default function CtaSection({ ...rest }) {
  return (
    <Cta backgroundImage={promoBg}>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-6 col-lg-8 col-md-10">
            <Cta.InnerWrapper className="text-center">
              <Cta.Subtitle fontColor="#ffce3e" as="span">{Data.ctaSection.subtitle}</Cta.Subtitle>
              <Cta.Title as="h2" fontColor="#fff">{Data.ctaSection.title}</Cta.Title>
              <Cta.Text fontColor="#fff">{Data.ctaSection.text}.</Cta.Text>
              <Cta.Button className="btn-torch-red" as={Link}  to={Data.ctaSection.ctaLink} rounded={true} sizeX="180px" sizeY="56px">{Data.ctaSection.ctaLabel}</Cta.Button>
            </Cta.InnerWrapper>
          </Col>
        </Row>
      </Container>
    </Cta>
  )
}