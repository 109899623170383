import React from "react";
import { PageWrapper } from "~components/Core";
import HeroSection from '~sections/Home/Hero'
import SolutionSection from '~sections/Home/Solutions'
import ValuesSection from '~sections/Home/Values'
import AboutSection from '~sections/Home/About'
import ContentSectionOne from '~sections/Home/ContentOne'
import CtaSection from '~sections/Home/Cta'
import FooterFive from '~sections/Common/Footer'

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
}

export default function Agency() {
  return (
    <PageWrapper headerConfig={header}>
        <HeroSection/>
        <ValuesSection/>
        <SolutionSection/>
        <AboutSection/>
        <ContentSectionOne/>
        <CtaSection/>
        <FooterFive/>
    </PageWrapper>
  )
}
