import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ServicesCard from './Components/Card'
import SectionTitle from './Components/SectionTitle'
import SolutionsData from '~data-yaml/home.yml'
import Service from './style'
import { useStaticQuery, graphql } from "gatsby"

const Solutions = () => {
  const data = useStaticQuery(graphql`
  query {
    allMdx(filter: {frontmatter: {type: {eq: "solutionDetails"}}},sort: {fields: [frontmatter___title], order: ASC}) {
      nodes {
        id
        excerpt
        slug
        frontmatter {
          title
          icon
          title
          iconColor
        }
      }
    }
  }
`)
  return (
    <Service backgroundColor="#f3f4f6">
      <Container>
        <Row className="row justify-content-center">
          <Col xs="12" className="col-xl-4 col-lg-6 col-md-6 col-sm-9 col-xs-10">
            <SectionTitle
              subTitle={SolutionsData.solutions.subtitle}
              title={SolutionsData.solutions.title}
              text={SolutionsData.solutions.text}
              subTitleProps={{ mb: "27px" }}
              titleProps={{ mb: "10px", as: "h2" }}
              mb="20px" />
          </Col>
          {data.allMdx.nodes.map(({ excerpt, slug, id, frontmatter }) => {
            return (
              <Col
                xs="12"
                className="col-xl-4 col-lg-6 col-md-6 col-sm-9 col-xs-10"
                key={id}
              >
                <ServicesCard
                  title={frontmatter.title}
                  text={excerpt}
                  icon={frontmatter.icon}
                  to={ slug }
                  iconBackground={frontmatter.iconColor}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
    </Service>
  )
}
export default Solutions