import React from 'react'
import ServicesCard from './Components/Card'
import Service from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
import Data from '../../../data-yaml/home.yml'

export default function ServicesSection() {
  return (
    <Service>
      <Container className="container">
        <Row className="row justify-content-center">
          <Col className="col-xl-8 text-center">
            <SectionTitle
              title="Our Values"
              titleProps={{ mb: "40px", mbLG: "75px" }}
              subTitleProps={{ mb: "20px" }}
            />
          </Col>
        </Row>
        <Row className="row justify-content-center">
          {Data.values.map((val, i) => {
            return (
              <Col xs="12" className="col-xl-4 col-xs-8" key={i}>
                <ServicesCard
                  title={val.title}
                  icon={val.icon}
                  iconColor={val.iconColor}
                  text={val.text}
                  key={"value-" + i}
                />
              </Col>
            )
          })}
       
        </Row>
      </Container>
    </Service >
  )
}

