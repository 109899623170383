import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Video from '~components/VideoModal'
import Hero from "./style"
import Home from "~data-yaml/home.yml"
import HeroBg from "~image/heroimages/home/hero_bg.jpg"
import heroOverlay from "~image/heroimages/home/overlay.png"

export default function HeroSection(){
return(
<Hero backgroundImage={HeroBg} backgroundOverlay={heroOverlay}>
  <Container>
    <Row className="align-items-center justify-content-center justify-content-md-start">
      {/* Welcome content Area */}
      <Col className="col-xxl-6 col-xl-7 col-lg-8 col-md-8 col-xs-11 order-2 order-lg-1">
        <Hero.Content mb="40px" mbMD="40px" mt="30px" mtLG="0">
        <Hero.Subtitle as="h3" fontColor="#fee856" mb="28px">{Home.hero.subtitle}</Hero.Subtitle>
        <Hero.Title as="h1" fontColor="#fff">{Home.hero.title}</Hero.Title>
          <Hero.Text>{Home.hero.text}</Hero.Text>
          {/*<Hero.Video mt="40px">
            <Video id={Home.hero.videoId} className="video-block">
              <i className="fa fa-play" />
              <span className="btn-text">{Home.hero.videoDescr} </span>
            </Video>
</Hero.Video>*/}
        </Hero.Content>
      </Col>
      {/*/ .Welcome Content Area */}
    </Row>
  </Container>
</Hero>
)
}